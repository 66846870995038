<template>
  <EKTable
    :items="employeesList"
    :columns="columns"
    selectedLabel="id"
    @details="openEmployeeDetails"
    @delete-selected="submitDeleteMultiEmployees"
  >
    <template slot="items.imageDto" slot-scope="{ value }">
      <b-avatar
        :src="
          value
            ? value.path
              ? value.path.replace(/^/, $store.getters['app/domainHost'] + '/')
              : ''
            : ''
        "
      ></b-avatar>
    </template>
  </EKTable>
</template>

<script>
import EKTable from "@Ekcore/components/EK-table";
import { mapActions, mapState ,mapGetters} from "vuex";
import { handleDashLangChange } from '@/libs/event-bus';
export default {
  components: {
    EKTable,
  },
  data: () => ({
    columns: [
      {
        label: "الصورة",
        field: "imageDto",
      },
      {
        label: "الاسم",
        field: "name",
      },
      {
        label: "المنصب الوظيفي",
        field: "positionName",
      },
      {
        label: "تفاصيل",
        field: "details",
        sortable: false,
      },
    ],
  }),
  created() {
    this.fetchEmployees();
    handleDashLangChange(this.fetchEmployees);
  },
  computed: {
    ...mapState({
      employees: (state) => state.employee.employees,
    }),
    ...mapGetters(['employeesList'])
  },
  methods: {
    ...mapActions(["fetchEmployees", "deleteMultiEmployees"]),
    openEmployeeDetails(props) {
      this.$router.push("/admin/employees/" + props.row.id);
    },
    submitDeleteMultiEmployees(selectedEmployees) {
      this.deleteMultiEmployees(selectedEmployees);
    },
  },
};
</script>

<style></style>
